import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <iframe title="HostPal" width="1024" height="804"
                src="https://app.powerbi.com/view?r=eyJrIjoiNjc0NzY3ZjktMjFjMS00YzgxLWJhMWEtZThhNGYyMTllNzEzIiwidCI6ImRjNGJiOGE0LTU1YWEtNDM2MC1hYTM1LWJmOThjZTc2YTMzYyJ9&pageName=ReportSection9b4f0dc709c89c086c26"
                frameBorder="0" allowFullScreen="true"></iframe>
    </div>
  );
}

export default App;
